import React, {Component} from "react";
import '../styles/semantic.min.css';
import {Accordion, Container, Grid, Header, Icon, Image, Item, Segment} from "semantic-ui-react";
import LayoutContainer from "../container/LayoutContainer";
import SEO from "../components/seo/Seo";
import MainNavigation from "../components/navigation/MainNavigation";
import Footer from "../components/footer/Footer";
import PageHeader from "../components/utils/PageHeader";
import projectImg from "../components/what-we-do/assets/operation.png";
import {Link} from "@reach/router";
import helpImg from "../components/what-we-do/assets/filmSnap1.jpg";
import DesktopContainer from "../container/DesktopContainer";
import {graphql, StaticQuery} from "gatsby";
import styles from "../components/what-we-do/doing.module.css";
import layout from "../templates/layout.module.css";
import TabletContainer from "../container/TabletContainer";
import MobileContainer from "../container/MobileContainer";

export default class WasWirTun extends Component {
    state = {
        activeIndex: -1,
        indices: [false, false, false, false, false, false, false]
    }

    handleClick = (e, titleProps) => {


        const {index} = titleProps
        const {activeIndex} = this.state
        const newIndex = activeIndex === index ? -1 : index
        let newIndices = this.state.indices;
        newIndices[index] = !newIndices[index];
        this.setState({activeIndex: newIndex})
        this.setState({indices: newIndices})

    }

    render() {
        const {activeIndex, indices} = this.state
        return (

            <LayoutContainer>
                <SEO title={'Was wir tun'}/>
                <StaticQuery query={projectQuery}
                             render={
                                 data => {
                                     const Projects = data.projects.edges.map(i =>
                                         <Item className={styles.contentItem}>
                                             <Icon className={styles.queryIcon} name={'chevron right'}/>
                                             <Item.Content>
                                                 <div className={styles.queryItems}
                                                      dangerouslySetInnerHTML={{__html: i.node.html}}/>
                                             </Item.Content>
                                         </Item>
                                     );
                                     const Aids = data.aids.edges.map(i =>
                                         <Item className={styles.contentItem}>
                                             <Icon className={styles.queryIcon} name={'chevron right'}/>
                                             <Item.Content>
                                                 <div className={styles.queryItems}
                                                      dangerouslySetInnerHTML={{__html: i.node.html}}/>
                                             </Item.Content>
                                         </Item>
                                     );
                                     return (
                                         <>
                                             <MobileContainer className={styles.mobile}>
                                                 <MainNavigation/>
                                                 <PageHeader title={'Was wir tun'}/>
                                                 <Container fluid>
                                                     <div className={styles.pageIntroContainer}>
                                                         <p className={styles.pageIntro}>Die Deutsche Stiftung
                                                             Kinderdermatologie
                                                             (DSK)
                                                             fördert gezielt durch Hilfsmaßnahmen
                                                             und Projekte die Beseitigung der gesundheitlichen
                                                             Unterversorgung.
                                                         </p>
                                                     </div>
                                                     <div>
                                                             <Item.Group>
                                                                 <Accordion className={styles.accordion}>
                                                                     <Item className={styles.item}>
                                                                         <Accordion.Title active={indices[0]}
                                                                                          index={0}
                                                                                          onClick={this.handleClick}>
                                                                             <Icon className={styles.itemIcon}
                                                                                   color='red' size='big'
                                                                                   name={'plus'}/>
                                                                             <div className={styles.itemHeader}>
                                                                                 DSK Hilfsfond
                                                                             </div>
                                                                         </Accordion.Title>
                                                                         <Accordion.Content active={indices[0]}>
                                                                             <p>Direkte Unterstützung hilfsbedürftiger hautkranker Kinder und Jugendliche (DSK-Hilfsfonds)
                                                                             </p>
                                                                         </Accordion.Content>
                                                                     </Item>
                                                                 </Accordion>
                                                                 <Accordion className={styles.accordion}>
                                                                     <Item className={styles.item}>
                                                                         <Accordion.Title active={indices[1]}
                                                                                          index={1}
                                                                                          onClick={this.handleClick}>
                                                                             <Icon className={styles.itemIcon}
                                                                                   color='green' size='big'
                                                                                   name={'hubspot'}/>
                                                                             <div className={styles.itemHeader}>
                                                                                 Bildung von Zentren
                                                                             </div>
                                                                         </Accordion.Title>
                                                                         <Accordion.Content active={indices[1]}>
                                                                             <p>Bildung von Zentren für Kinder- und Jugenddermatologie und –allergologie (KIND)
                                                                             </p>
                                                                         </Accordion.Content>
                                                                     </Item>
                                                                 </Accordion>

                                                                 <Accordion className={styles.accordion}>
                                                                     <Item className={styles.item}>
                                                                         <Accordion.Title active={indices[2]}
                                                                                          index={2}
                                                                                          onClick={this.handleClick}>
                                                                             <Icon className={styles.itemIcon}
                                                                                   color='violet' size='big'
                                                                                   name={'hospital'}/>
                                                                             <div className={styles.itemHeader}>
                                                                                 Einrichtungen zur Behandlung
                                                                                 hautkranker Kinder und Jugendliche
                                                                             </div>
                                                                         </Accordion.Title>
                                                                         <Accordion.Content active={indices[2]}>
                                                                             <p>Unterstützung bestehender Einrichtungen
                                                                                 zur Behandlung von hautkranken Kindern
                                                                                 und Jugendlichen
                                                                             </p>
                                                                         </Accordion.Content>
                                                                     </Item>
                                                                 </Accordion>

                                                                 <Accordion className={styles.accordion}>
                                                                     <Item className={styles.item}>
                                                                         <Accordion.Title active={indices[3]}
                                                                                          index={3}
                                                                                          onClick={this.handleClick}>
                                                                             <Icon className={styles.itemIcon}
                                                                                   color='orange' size='big'
                                                                                   name={'pills'}/>
                                                                             <div className={styles.itemHeader}>
                                                                                 Patientenschulungen
                                                                             </div>
                                                                         </Accordion.Title>
                                                                         <Accordion.Content active={indices[3]}>
                                                                             <p>Förderung der Patientenschulung von
                                                                                 Kindern und Jugendlichen mit
                                                                                 verschiedenen Hauterkrankungen
                                                                             </p>
                                                                         </Accordion.Content>
                                                                     </Item>
                                                                 </Accordion>

                                                                 <Accordion className={styles.accordion}>
                                                                     <Item className={styles.item}>
                                                                         <Accordion.Title active={indices[4]}
                                                                                          index={4}
                                                                                          onClick={this.handleClick}>
                                                                             <Icon className={styles.itemIcon}
                                                                                   color='blue' size='big'
                                                                                   name={'university'}/>
                                                                             <div className={styles.itemHeader}>
                                                                                 Förderung der Wissenschaft, Forschung,
                                                                                 Lehre und Öffentlichkeitsarbeit
                                                                             </div>
                                                                         </Accordion.Title>
                                                                         <Accordion.Content active={indices[4]}>
                                                                             <p>Förderung von Wissenschaft, Forschung
                                                                                 und Lehre sowie Aufklärung der
                                                                                 Öffentlichkeit im Bereich der
                                                                                 Kinderdermatologie
                                                                             </p>
                                                                         </Accordion.Content>
                                                                     </Item>
                                                                 </Accordion>

                                                                 <Accordion className={styles.accordion}>
                                                                     <Item className={styles.item}>
                                                                         <Accordion.Title active={indices[5]}
                                                                                          index={5}
                                                                                          onClick={this.handleClick}>
                                                                             <Icon className={styles.itemIcon}
                                                                                   color='yellow' size='big'
                                                                                   name={'trophy'}/>
                                                                             <div className={styles.itemHeader}>
                                                                                 DSK-Förderpreis Kinderdermatologie
                                                                             </div>
                                                                         </Accordion.Title>
                                                                         <Accordion.Content active={indices[5]}>
                                                                             <p>Jährliche Preisvergabe für hervorragende Arbeiten in Forschung, Praxis und Klinik mit dem DSK-Förderpreis Kinderdermatologie
                                                                             </p>
                                                                         </Accordion.Content>
                                                                     </Item>
                                                                 </Accordion>

                                                                 <Accordion className={styles.accordion}>
                                                                     <Item className={styles.item}>
                                                                         <Accordion.Title active={indices[6]}
                                                                                          index={6}
                                                                                          onClick={this.handleClick}>
                                                                             <Icon className={styles.itemIcon}
                                                                                   color='teal' size='big'
                                                                                   name={'graduation cap'}/>
                                                                             <div className={styles.itemHeader}>
                                                                                 Stipendienprogramm
                                                                             </div>
                                                                         </Accordion.Title>
                                                                         <Accordion.Content active={indices[6]}>
                                                                             <p>Systematisches Stipendienprogramm „Fit
                                                                                 für Kinderdermatologie“ zur Förderung
                                                                                 des begabten, Engagement bereiten und
                                                                                 lernwilligen Nachwuchses
                                                                             </p>
                                                                         </Accordion.Content>
                                                                     </Item>
                                                                 </Accordion>

                                                                 <Accordion className={styles.accordion}>
                                                                     <Item className={styles.item}>
                                                                         <Accordion.Title active={indices[7]}
                                                                                          index={7}
                                                                                          onClick={this.handleClick}>
                                                                             <Icon className={styles.itemIcon}
                                                                                   color='purple' size='big'
                                                                                   name={'user md'}/>
                                                                             <div className={styles.itemHeader}>
                                                                                 Förderung der Fortbildung und
                                                                                 Ausbildung des
                                                                                 medizinischen
                                                                                 Personals
                                                                             </div>
                                                                         </Accordion.Title>
                                                                         <Accordion.Content active={indices[7]}>
                                                                             <p>
                                                                                 Aus- und Fortbildung von Ärzten und
                                                                                 Pflegepersonals im Fach
                                                                                 Kinderdermatologie (Grund-, Aufbau und
                                                                                 Spezialseminar mit Zertifikat
                                                                                 „Kinderdermatologie“)
                                                                             </p>
                                                                         </Accordion.Content>
                                                                     </Item>
                                                                 </Accordion>

                                                             </Item.Group>
                                                     </div>
                                                     <Grid stackable relaxed={'very'}>
                                                         <p id={'projekte'} className={styles.anchor}/>
                                                         <Grid.Row columns={2}>
                                                             <Grid.Column verticalAlign={'middle'}>
                                                                 <Header as={'h4'} style={header}>Projekte</Header>
                                                                 <p className={styles.pageIntro}>Die Deutsche Stiftung
                                                                     Kinderdermatologie (DSK) fördert gezielt durch
                                                                     Projekte die
                                                                     Beseitigung der gesundheitlichen
                                                                     Unterversorgung.</p>
                                                             </Grid.Column>
                                                             <Grid.Column>
                                                                 <Image src={projectImg} centered
                                                                        className={styles.introImg}/>
                                                             </Grid.Column>
                                                         </Grid.Row>
                                                         <>
                                                             <Item.Group className={styles.itemGroup}>
                                                                 {Projects}
                                                             </Item.Group>
                                                         </>
                                                         <p id={'hilfe'} className={styles.anchor}/>
                                                         <Grid.Row columns={2}>
                                                             <Grid.Column verticalAlign={'middle'}>
                                                                 <Header as={'h4'}
                                                                         style={header}>Hilfsmaßnahmen</Header>
                                                                 <p className={styles.pageIntro}>Die Deutsche Stiftung
                                                                     Kinderdermatologie (DSK) unterstützt direkt
                                                                     Familien mit
                                                                     hautkranken Kindern in Not. Betroffene erhalten
                                                                     ihre
                                                                     Unterstützung durch den DSK-Hilfsfond.</p>
                                                             </Grid.Column>
                                                             <Grid.Column>
                                                                 <Image src={helpImg} centered
                                                                        className={styles.introImg}/>
                                                             </Grid.Column>
                                                             <Segment className={styles.aidsSegment}>
                                                                 <p>Die Deutsche Stiftung Kinderdermatologie hat einen
                                                                     DSK-Hilfsfonds eingerichtet. Hiervon sollen vor
                                                                     allem
                                                                     hautkranke Kinder und Jugendliche profitieren, die
                                                                     in Not
                                                                     sind.
                                                                     Die Unterstützung soll schnell und ohne große
                                                                     bürokratische
                                                                     Hürden in solchen Fällen helfen, wo Versicherungen
                                                                     und
                                                                     andere
                                                                     Leistungsträger nicht helfen können.</p>
                                                                 <p>Die DSK unterstützt derzeit Kinder mit schwerer
                                                                     Neurodermitis
                                                                     und Epidermolysis bullosa
                                                                     (Schmetterlingskrankheit).
                                                                     Ein <Link
                                                                         to={'/was-wir-tun/hilfe/dominik-f/'}
                                                                         className={'primary'}>Beispiel
                                                                         Dominik F.</Link>, 16 Jahre alt mit schwerer
                                                                     Schmetterlingskrankheit.</p>
                                                                 <p>Der Fonds hilft hier bei der Kostenübernahme für
                                                                     Mehraufwendung
                                                                     bei Reisekosten zur Vorstellung im Epidermolysis
                                                                     bullosa
                                                                     Zentrum der Universitätsklinik Freiburg, für
                                                                     druckentlastendes
                                                                     Schuhwerk und leichte Brille aus Kunststoff zur
                                                                     Druckminderung
                                                                     und Schutzwäsche aus Spezialseide, damit die Haut
                                                                     nicht
                                                                     verletzt wird.</p>
                                                                 <p>Ärzte, die hautkranke Kinder und Jugendliche
                                                                     betreuen oder
                                                                     deren
                                                                     Angehörige können sich direkt an die DSK wenden.
                                                                     Den
                                                                     Betroffenen wird sodann ein Förderantrag
                                                                     zugesendet. Der
                                                                     DSK-Hilfsfonds ist Teil des ersten Schwerpunktes
                                                                     des <Link
                                                                         to={'/was-wir-tun/foerderprogramm'}
                                                                         className={'primary'}>8-Punkteförderprogramms
                                                                         (8-PFP)</Link> der DSK. Als Erkennungszeichen
                                                                     dient ein
                                                                     Logo mit der helfenden Hand.</p>
                                                             </Segment>
                                                             <Header as={'h4'}
                                                                     className={styles.aidsDisclaimer}>
                                                                 Auswahl an Betroffenen:
                                                             </Header>
                                                             <div style={{marginBottom: '2rem'}}>
                                                                 <Item.Group className={styles.itemGroup}>
                                                                     {Aids}
                                                                 </Item.Group>
                                                             </div>
                                                         </Grid.Row>
                                                     </Grid>
                                                 </Container>
                                                 <Footer/>
                                             </MobileContainer>
                                             <TabletContainer className={styles.tablet}>
                                                 <MainNavigation/>
                                                 <PageHeader title={'Was wir tun'}/>
                                                 <Container fluid>
                                                     <Container className={styles.pageIntroContainer}>
                                                         <p className={styles.pageIntro}>Die Deutsche Stiftung
                                                             Kinderdermatologie
                                                             (DSK)
                                                             fördert gezielt durch Hilfsmaßnahmen
                                                             und Projekte die Beseitigung der gesundheitlichen
                                                             Unterversorgung.
                                                         </p>
                                                     </Container>
                                                     <Container>
                                                         <Item.Group>
                                                             <Accordion className={styles.accordion}>
                                                                 <Item className={styles.item}>
                                                                     <Accordion.Title active={indices[0]}
                                                                                      index={0}
                                                                                      onClick={this.handleClick}>
                                                                         <Icon className={styles.itemIcon}
                                                                               color='red' size='big'
                                                                               name={'plus'}/>
                                                                         <div className={styles.itemHeader}>
                                                                             DSK Hilfsfond
                                                                         </div>
                                                                     </Accordion.Title>
                                                                     <Accordion.Content active={indices[0]}>
                                                                         <p>Direkte Unterstützung hilfsbedürftiger hautkranker Kinder und Jugendliche (DSK-Hilfsfonds)
                                                                         </p>
                                                                     </Accordion.Content>
                                                                 </Item>
                                                             </Accordion>
                                                             <Accordion className={styles.accordion}>
                                                                 <Item className={styles.item}>
                                                                     <Accordion.Title active={indices[1]}
                                                                                      index={1}
                                                                                      onClick={this.handleClick}>
                                                                         <Icon className={styles.itemIcon}
                                                                               color='green' size='big'
                                                                               name={'hubspot'}/>
                                                                         <div className={styles.itemHeader}>
                                                                             Bildung von Zentren
                                                                         </div>
                                                                     </Accordion.Title>
                                                                     <Accordion.Content active={indices[1]}>
                                                                         <p>Bildung von Zentren für Kinder- und Jugenddermatologie und –allergologie (KIND)
                                                                         </p>
                                                                     </Accordion.Content>
                                                                 </Item>
                                                             </Accordion>

                                                             <Accordion className={styles.accordion}>
                                                                 <Item className={styles.item}>
                                                                     <Accordion.Title active={indices[2]}
                                                                                      index={2}
                                                                                      onClick={this.handleClick}>
                                                                         <Icon className={styles.itemIcon}
                                                                               color='violet' size='big'
                                                                               name={'hospital'}/>
                                                                         <div className={styles.itemHeader}>
                                                                             Einrichtungen zur Behandlung
                                                                             hautkranker Kinder und Jugendliche
                                                                         </div>
                                                                     </Accordion.Title>
                                                                     <Accordion.Content active={indices[2]}>
                                                                         <p>Unterstützung bestehender Einrichtungen
                                                                             zur Behandlung von hautkranken Kindern
                                                                             und Jugendlichen
                                                                         </p>
                                                                     </Accordion.Content>
                                                                 </Item>
                                                             </Accordion>

                                                             <Accordion className={styles.accordion}>
                                                                 <Item className={styles.item}>
                                                                     <Accordion.Title active={indices[3]}
                                                                                      index={3}
                                                                                      onClick={this.handleClick}>
                                                                         <Icon className={styles.itemIcon}
                                                                               color='orange' size='big'
                                                                               name={'pills'}/>
                                                                         <div className={styles.itemHeader}>
                                                                             Patientenschulungen
                                                                         </div>
                                                                     </Accordion.Title>
                                                                     <Accordion.Content active={indices[3]}>
                                                                         <p>Förderung der Patientenschulung von
                                                                             Kindern und Jugendlichen mit
                                                                             verschiedenen Hauterkrankungen
                                                                         </p>
                                                                     </Accordion.Content>
                                                                 </Item>
                                                             </Accordion>

                                                             <Accordion className={styles.accordion}>
                                                                 <Item className={styles.item}>
                                                                     <Accordion.Title active={indices[4]}
                                                                                      index={4}
                                                                                      onClick={this.handleClick}>
                                                                         <Icon className={styles.itemIcon}
                                                                               color='blue' size='big'
                                                                               name={'university'}/>
                                                                         <div className={styles.itemHeader}>
                                                                             Förderung der Wissenschaft, Forschung,
                                                                             Lehre und Öffentlichkeitsarbeit
                                                                         </div>
                                                                     </Accordion.Title>
                                                                     <Accordion.Content active={indices[4]}>
                                                                         <p>Förderung von Wissenschaft, Forschung
                                                                             und Lehre sowie Aufklärung der
                                                                             Öffentlichkeit im Bereich der
                                                                             Kinderdermatologie
                                                                         </p>
                                                                     </Accordion.Content>
                                                                 </Item>
                                                             </Accordion>

                                                             <Accordion className={styles.accordion}>
                                                                 <Item className={styles.item}>
                                                                     <Accordion.Title active={indices[5]}
                                                                                      index={5}
                                                                                      onClick={this.handleClick}>
                                                                         <Icon className={styles.itemIcon}
                                                                               color='yellow' size='big'
                                                                               name={'trophy'}/>
                                                                         <div className={styles.itemHeader}>
                                                                             DSK-Förderpreis Kinderdermatologie
                                                                         </div>
                                                                     </Accordion.Title>
                                                                     <Accordion.Content active={indices[5]}>
                                                                         <p>Jährliche Preisvergabe für hervorragende Arbeiten in Forschung, Praxis und Klinik mit dem DSK-Förderpreis Kinderdermatologie
                                                                         </p>
                                                                     </Accordion.Content>
                                                                 </Item>
                                                             </Accordion>

                                                             <Accordion className={styles.accordion}>
                                                                 <Item className={styles.item}>
                                                                     <Accordion.Title active={indices[6]}
                                                                                      index={6}
                                                                                      onClick={this.handleClick}>
                                                                         <Icon className={styles.itemIcon}
                                                                               color='teal' size='big'
                                                                               name={'graduation cap'}/>
                                                                         <div className={styles.itemHeader}>
                                                                             Stipendienprogramm
                                                                         </div>
                                                                     </Accordion.Title>
                                                                     <Accordion.Content active={indices[6]}>
                                                                         <p>Systematisches Stipendienprogramm „Fit
                                                                             für Kinderdermatologie“ zur Förderung
                                                                             des begabten, Engagement bereiten und
                                                                             lernwilligen Nachwuchses
                                                                         </p>
                                                                     </Accordion.Content>
                                                                 </Item>
                                                             </Accordion>

                                                             <Accordion className={styles.accordion}>
                                                                 <Item className={styles.item}>
                                                                     <Accordion.Title active={indices[7]}
                                                                                      index={7}
                                                                                      onClick={this.handleClick}>
                                                                         <Icon className={styles.itemIcon}
                                                                               color='purple' size='big'
                                                                               name={'user md'}/>
                                                                         <div className={styles.itemHeader}>
                                                                             Förderung der Fortbildung und
                                                                             Ausbildung des
                                                                             medizinischen
                                                                             Personals
                                                                         </div>
                                                                     </Accordion.Title>
                                                                     <Accordion.Content active={indices[7]}>
                                                                         <p>
                                                                             Aus- und Fortbildung von Ärzten und
                                                                             Pflegepersonals im Fach
                                                                             Kinderdermatologie (Grund-, Aufbau und
                                                                             Spezialseminar mit Zertifikat
                                                                             „Kinderdermatologie“)
                                                                         </p>
                                                                     </Accordion.Content>
                                                                 </Item>
                                                             </Accordion>

                                                         </Item.Group>
                                                     </Container>
                                                     <Grid stackable container relaxed={'very'}>
                                                         <p id={'projekte'} className={styles.anchor}/>
                                                         <Grid.Row columns={2}>
                                                             <Grid.Column verticalAlign={'middle'}>
                                                                 <Header as={'h4'} style={header}>Projekte</Header>
                                                                 <p className={styles.pageIntro}>Die Deutsche Stiftung
                                                                     Kinderdermatologie (DSK) fördert gezielt durch
                                                                     Projekte die
                                                                     Beseitigung der gesundheitlichen
                                                                     Unterversorgung.</p>
                                                             </Grid.Column>
                                                             <Grid.Column>
                                                                 <Image src={projectImg} centered
                                                                        className={styles.introImg}/>
                                                             </Grid.Column>
                                                         </Grid.Row>
                                                         <Container>
                                                             <Item.Group className={styles.itemGroup}>
                                                                 {Projects}
                                                             </Item.Group>
                                                         </Container>
                                                         <p id={'hilfe'} className={styles.anchor}/>
                                                         <Grid.Row columns={2}>
                                                             <Grid.Column verticalAlign={'middle'}>
                                                                 <Header as={'h4'}
                                                                         style={header}>Hilfsmaßnahmen</Header>
                                                                 <p className={styles.pageIntro}>Die Deutsche Stiftung
                                                                     Kinderdermatologie (DSK) unterstützt direkt
                                                                     Familien mit
                                                                     hautkranken Kindern in Not. Betroffene erhalten
                                                                     ihre
                                                                     Unterstützung durch den DSK-Hilfsfond.</p>
                                                             </Grid.Column>
                                                             <Grid.Column>
                                                                 <Image src={helpImg} centered
                                                                        className={styles.introImg}/>
                                                             </Grid.Column>
                                                             <Container>
                                                                 <Segment className={styles.aidsSegment}>
                                                                     <p>Die Deutsche Stiftung Kinderdermatologie hat
                                                                         einen
                                                                         DSK-Hilfsfonds eingerichtet. Hiervon sollen vor
                                                                         allem
                                                                         hautkranke Kinder und Jugendliche profitieren,
                                                                         die in Not
                                                                         sind.
                                                                         Die Unterstützung soll schnell und ohne große
                                                                         bürokratische
                                                                         Hürden in solchen Fällen helfen, wo
                                                                         Versicherungen und
                                                                         andere
                                                                         Leistungsträger nicht helfen können.</p>
                                                                     <p>Die DSK unterstützt derzeit Kinder mit schwerer
                                                                         Neurodermitis
                                                                         und Epidermolysis bullosa
                                                                         (Schmetterlingskrankheit).
                                                                         Ein <Link
                                                                             to={'/was-wir-tun/hilfe/dominik-f/'}
                                                                             className={'primary'}>Beispiel
                                                                             Dominik F.</Link>, 16 Jahre alt mit
                                                                         schwerer
                                                                         Schmetterlingskrankheit.</p>
                                                                     <p>Der Fonds hilft hier bei der Kostenübernahme für
                                                                         Mehraufwendung
                                                                         bei Reisekosten zur Vorstellung im
                                                                         Epidermolysis bullosa
                                                                         Zentrum der Universitätsklinik Freiburg, für
                                                                         druckentlastendes
                                                                         Schuhwerk und leichte Brille aus Kunststoff zur
                                                                         Druckminderung
                                                                         und Schutzwäsche aus Spezialseide, damit die
                                                                         Haut nicht
                                                                         verletzt wird.</p>
                                                                     <p>Ärzte, die hautkranke Kinder und Jugendliche
                                                                         betreuen oder
                                                                         deren
                                                                         Angehörige können sich direkt an die DSK
                                                                         wenden. Den
                                                                         Betroffenen wird sodann ein Förderantrag
                                                                         zugesendet. Der
                                                                         DSK-Hilfsfonds ist Teil des ersten
                                                                         Schwerpunktes des <Link
                                                                             to={'/was-wir-tun/foerderprogramm'}
                                                                             className={'primary'}>8-Punkteförderprogramms
                                                                             (8-PFP)</Link> der DSK. Als
                                                                         Erkennungszeichen dient ein
                                                                         Logo mit der helfenden Hand.</p>
                                                                 </Segment>
                                                                 <Header as={'h4'}
                                                                         className={styles.aidsDisclaimer}>
                                                                     Auswahl an Betroffenen:
                                                                 </Header>
                                                                 <div style={{marginBottom: '2rem'}}>
                                                                     <Item.Group className={styles.itemGroup}>
                                                                         {Aids}
                                                                     </Item.Group>
                                                                 </div>
                                                             </Container>
                                                         </Grid.Row>
                                                     </Grid>
                                                 </Container>
                                                 <Footer/>
                                             </TabletContainer>
                                             <DesktopContainer>
                                                 <div className={layout.pageWrapper}>
                                                     <MainNavigation/>
                                                     <PageHeader title={'Was wir tun'}/>
                                                     <Container fluid>
                                                         <Container className={styles.pageIntroContainer}>
                                                             <p className={styles.pageIntro}>Die Deutsche Stiftung
                                                                 Kinderdermatologie (DSK)
                                                                 fördert gezielt durch Hilfsmaßnahmen
                                                                 und Projekte die Beseitigung der gesundheitlichen
                                                                 Unterversorgung.
                                                             </p>
                                                         </Container>
                                                         <Container>
                                                             <Item.Group>
                                                                <Accordion className={styles.accordion}>
                                                                     <Item className={styles.item}>
                                                                         <Accordion.Title active={indices[0]}
                                                                                          index={0}
                                                                                          onClick={this.handleClick}>
                                                                             <Icon className={styles.itemIcon}
                                                                                   color='red' size='big'
                                                                                   name={'plus'}/>
                                                                             <div className={styles.itemHeader}>
                                                                                 DSK Hilfsfond
                                                                             </div>
                                                                         </Accordion.Title>
                                                                         <Accordion.Content active={indices[0]}>
                                                                             <p>Direkte Unterstützung hilfsbedürftiger hautkranker Kinder und Jugendliche (DSK-Hilfsfonds)
                                                                             </p>
                                                                         </Accordion.Content>
                                                                     </Item>
                                                                 </Accordion>
                                                                 <Accordion className={styles.accordion}>
                                                                     <Item className={styles.item}>
                                                                         <Accordion.Title active={indices[1]}
                                                                                          index={1}
                                                                                          onClick={this.handleClick}>
                                                                             <Icon className={styles.itemIcon}
                                                                                   color='green' size='big'
                                                                                   name={'hubspot'}/>
                                                                             <div className={styles.itemHeader}>
                                                                                 Bildung von Zentren
                                                                             </div>
                                                                         </Accordion.Title>
                                                                         <Accordion.Content active={indices[1]}>
                                                                             <p>Bildung von Zentren für Kinder- und Jugenddermatologie und –allergologie (KIND)
                                                                             </p>
                                                                         </Accordion.Content>
                                                                     </Item>
                                                                 </Accordion>

                                                                 <Accordion className={styles.accordion}>
                                                                     <Item className={styles.item}>
                                                                         <Accordion.Title active={indices[2]}
                                                                                          index={2}
                                                                                          onClick={this.handleClick}>
                                                                             <Icon className={styles.itemIcon}
                                                                                   color='violet' size='big'
                                                                                   name={'hospital'}/>
                                                                             <div className={styles.itemHeader}>
                                                                                 Einrichtungen zur Behandlung
                                                                                 hautkranker Kinder und Jugendliche
                                                                             </div>
                                                                         </Accordion.Title>
                                                                         <Accordion.Content active={indices[2]}>
                                                                             <p>Unterstützung bestehender Einrichtungen
                                                                                 zur Behandlung von hautkranken Kindern
                                                                                 und Jugendlichen
                                                                             </p>
                                                                         </Accordion.Content>
                                                                     </Item>
                                                                 </Accordion>

                                                                 <Accordion className={styles.accordion}>
                                                                     <Item className={styles.item}>
                                                                         <Accordion.Title active={indices[3]}
                                                                                          index={3}
                                                                                          onClick={this.handleClick}>
                                                                             <Icon className={styles.itemIcon}
                                                                                   color='orange' size='big'
                                                                                   name={'pills'}/>
                                                                             <div className={styles.itemHeader}>
                                                                                 Patientenschulungen
                                                                             </div>
                                                                         </Accordion.Title>
                                                                         <Accordion.Content active={indices[3]}>
                                                                             <p>Förderung der Patientenschulung von
                                                                                 Kindern und Jugendlichen mit
                                                                                 verschiedenen Hauterkrankungen
                                                                             </p>
                                                                         </Accordion.Content>
                                                                     </Item>
                                                                 </Accordion>

                                                                 <Accordion className={styles.accordion}>
                                                                     <Item className={styles.item}>
                                                                         <Accordion.Title active={indices[4]}
                                                                                          index={4}
                                                                                          onClick={this.handleClick}>
                                                                             <Icon className={styles.itemIcon}
                                                                                   color='blue' size='big'
                                                                                   name={'university'}/>
                                                                             <div className={styles.itemHeader}>
                                                                                 Förderung der Wissenschaft, Forschung,
                                                                                 Lehre und Öffentlichkeitsarbeit
                                                                             </div>
                                                                         </Accordion.Title>
                                                                         <Accordion.Content active={indices[4]}>
                                                                             <p>Förderung von Wissenschaft, Forschung
                                                                                 und Lehre sowie Aufklärung der
                                                                                 Öffentlichkeit im Bereich der
                                                                                 Kinderdermatologie
                                                                             </p>
                                                                         </Accordion.Content>
                                                                     </Item>
                                                                 </Accordion>

                                                                 <Accordion className={styles.accordion}>
                                                                     <Item className={styles.item}>
                                                                         <Accordion.Title active={indices[5]}
                                                                                          index={5}
                                                                                          onClick={this.handleClick}>
                                                                             <Icon className={styles.itemIcon}
                                                                                   color='yellow' size='big'
                                                                                   name={'trophy'}/>
                                                                             <div className={styles.itemHeader}>
                                                                                 DSK-Förderpreis Kinderdermatologie
                                                                             </div>
                                                                         </Accordion.Title>
                                                                         <Accordion.Content active={indices[5]}>
                                                                             <p>Jährliche Preisvergabe für hervorragende Arbeiten in Forschung, Praxis und Klinik mit dem DSK-Förderpreis Kinderdermatologie
                                                                             </p>
                                                                         </Accordion.Content>
                                                                     </Item>
                                                                 </Accordion>

                                                                 <Accordion className={styles.accordion}>
                                                                     <Item className={styles.item}>
                                                                         <Accordion.Title active={indices[6]}
                                                                                          index={6}
                                                                                          onClick={this.handleClick}>
                                                                             <Icon className={styles.itemIcon}
                                                                                   color='teal' size='big'
                                                                                   name={'graduation cap'}/>
                                                                             <div className={styles.itemHeader}>
                                                                                 Stipendienprogramm
                                                                             </div>
                                                                         </Accordion.Title>
                                                                         <Accordion.Content active={indices[6]}>
                                                                             <p>Systematisches Stipendienprogramm „Fit
                                                                                 für Kinderdermatologie“ zur Förderung
                                                                                 des begabten, Engagement bereiten und
                                                                                 lernwilligen Nachwuchses
                                                                             </p>
                                                                         </Accordion.Content>
                                                                     </Item>
                                                                 </Accordion>

                                                                 <Accordion className={styles.accordion}>
                                                                     <Item className={styles.item}>
                                                                         <Accordion.Title active={indices[7]}
                                                                                          index={7}
                                                                                          onClick={this.handleClick}>
                                                                             <Icon className={styles.itemIcon}
                                                                                   color='purple' size='big'
                                                                                   name={'user md'}/>
                                                                             <div className={styles.itemHeader}>
                                                                                 Förderung der Fortbildung und
                                                                                 Ausbildung des
                                                                                 medizinischen
                                                                                 Personals
                                                                             </div>
                                                                         </Accordion.Title>
                                                                         <Accordion.Content active={indices[7]}>
                                                                             <p>
                                                                                 Aus- und Fortbildung von Ärzten und
                                                                                 Pflegepersonals im Fach
                                                                                 Kinderdermatologie (Grund-, Aufbau und
                                                                                 Spezialseminar mit Zertifikat
                                                                                 „Kinderdermatologie“)
                                                                             </p>
                                                                         </Accordion.Content>
                                                                     </Item>
                                                                 </Accordion>

                                                             </Item.Group>
                                                         </Container>
                                                         <Grid container stackable relaxed={'very'}>
                                                             <p id={'projekte'} className={styles.anchor}/>
                                                             <Grid.Row columns={2}>
                                                                 <Grid.Column verticalAlign={'middle'}>
                                                                     <Header as={'h4'} style={header}>Projekte</Header>
                                                                     <p className={styles.pageIntro}>Die Deutsche
                                                                         Stiftung
                                                                         Kinderdermatologie (DSK) fördert gezielt durch
                                                                         Projekte
                                                                         die
                                                                         Beseitigung der gesundheitlichen
                                                                         Unterversorgung.</p>
                                                                 </Grid.Column>
                                                                 <Grid.Column>
                                                                     <Image src={projectImg} centered
                                                                            className={styles.introImg}/>
                                                                 </Grid.Column>
                                                             </Grid.Row>
                                                             <Container>
                                                                 <Item.Group>
                                                                     {Projects}
                                                                 </Item.Group>
                                                             </Container>
                                                             <p id={'hilfe'} className={styles.anchor}/>
                                                             <Grid.Row columns={2}>
                                                                 <Grid.Column>
                                                                     <Image src={helpImg} centered
                                                                            className={styles.introImg}/>
                                                                 </Grid.Column>
                                                                 <Grid.Column verticalAlign={'middle'}>
                                                                     <Header as={'h4'}
                                                                             style={header}>Hilfsmaßnahmen</Header>
                                                                     <p className={styles.pageIntro}>Die Deutsche
                                                                         Stiftung
                                                                         Kinderdermatologie (DSK) unterstützt direkt
                                                                         Familien
                                                                         mit
                                                                         hautkranken Kindern in Not. Betroffene erhalten
                                                                         ihre
                                                                         Unterstützung durch den DSK-Hilfsfond.</p>
                                                                 </Grid.Column>
                                                                 <Container>
                                                                     <Segment className={styles.aidsSegment}>
                                                                         <p>Die Deutsche Stiftung Kinderdermatologie hat
                                                                             einen
                                                                             DSK-Hilfsfonds eingerichtet. Hiervon sollen
                                                                             vor
                                                                             allem
                                                                             hautkranke Kinder und Jugendliche
                                                                             profitieren, die
                                                                             in Not
                                                                             sind.
                                                                             Die Unterstützung soll schnell und ohne
                                                                             große
                                                                             bürokratische
                                                                             Hürden in solchen Fällen helfen, wo
                                                                             Versicherungen
                                                                             und
                                                                             andere
                                                                             Leistungsträger nicht helfen können.</p>
                                                                         <p>Die DSK unterstützt derzeit Kinder mit
                                                                             schwerer
                                                                             Neurodermitis
                                                                             und Epidermolysis bullosa
                                                                             (Schmetterlingskrankheit).
                                                                             Ein <Link
                                                                                 to={'/was-wir-tun/hilfe/dominik-f/'}
                                                                                 className={'primary'}>Beispiel
                                                                                 Dominik F.</Link>, 16 Jahre alt mit
                                                                             schwerer
                                                                             Schmetterlingskrankheit.</p>
                                                                         <p>Der Fonds hilft hier bei der Kostenübernahme
                                                                             für
                                                                             Mehraufwendung
                                                                             bei Reisekosten zur Vorstellung im
                                                                             Epidermolysis
                                                                             bullosa
                                                                             Zentrum der Universitätsklinik Freiburg,
                                                                             für
                                                                             druckentlastendes
                                                                             Schuhwerk und leichte Brille aus Kunststoff
                                                                             zur
                                                                             Druckminderung
                                                                             und Schutzwäsche aus Spezialseide, damit
                                                                             die Haut
                                                                             nicht
                                                                             verletzt wird.</p>
                                                                         <p>Ärzte, die hautkranke Kinder und Jugendliche
                                                                             betreuen oder
                                                                             deren
                                                                             Angehörige können sich direkt an die DSK
                                                                             wenden.
                                                                             Den
                                                                             Betroffenen wird sodann ein Förderantrag
                                                                             zugesendet. Der
                                                                             DSK-Hilfsfonds ist Teil des ersten
                                                                             Schwerpunktes
                                                                             des <Link
                                                                                 to={'/was-wir-tun/foerderprogramm'}
                                                                                 className={'primary'}>8-Punkteförderprogramms
                                                                                 (8-PFP)</Link> der DSK. Als
                                                                             Erkennungszeichen
                                                                             dient ein
                                                                             Logo mit der helfenden Hand.</p>
                                                                     </Segment>
                                                                 </Container>
                                                                 <Container>
                                                                     <Header as={'h4'}
                                                                             className={styles.aidsDisclaimer}>
                                                                         Auswahl an Betroffenen:
                                                                     </Header>
                                                                 </Container>
                                                                 <Container style={{marginBottom: '2rem'}}>
                                                                     <Item.Group>
                                                                         {Aids}
                                                                     </Item.Group>
                                                                 </Container>
                                                             </Grid.Row>
                                                         </Grid>
                                                     </Container>
                                                     <Footer/>
                                                 </div>
                                             </DesktopContainer>

                                         </>
                                     )
                                 }
                             }
                />
            </LayoutContainer>

        )
    }
}


const header = {
    fontSize: "1.33rem"
};

const projectQuery = graphql`
query projectQuery {
    projects: allMarkdownRemark( 
      filter:{
        frontmatter: {type: { eq: "projects" }}
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      ){
      edges {
        node {
          html
            frontmatter {
                title
                path
            }
        }
      }
    }
  aids: allMarkdownRemark( 
      filter:{
        frontmatter: {type: { eq: "aid" }}
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      ){
      edges {
        node {
          html
            frontmatter {
                title
                path
            }
        }
      }
    }
}
`;